import React from "react";
import Routings from "./Routings";
//import "./App.css";

export const App = () => {
  return (
    <>
      <Routings />
    </>
  );
};
