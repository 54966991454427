import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./Flutter.css";
import { getFlutterSettings } from "../selectors";
import { saveSettingsFlutter } from "../actions";

let isOnline = true;
let isDebug = true;

const flutter = isOnline
  ? isDebug
    ? "https://fluttersystems.com"
    : "https://clientsystem.net"
  : "http://192.168.0.168:8000";

const width = 0.6;

function Flutter({ onSaveSettingsFlutter, settings }) {

  return (
    <>
      <main className="flutter-main">
        <br></br>
        <h1>Flutter Frontend</h1>
          <iframe
            width={window.innerWidth * width}
            height={(window.innerWidth * width * 9) / 16}
            src={flutter + "/flutter"}
          />

        <br />

        <p className="instructions-flutter">
          This demo is made in Flutter with Unity plugin and works also on
          mobile phones and tablets. Though for small screens one can use
          fluttersystems.net/flutter to skip the web part. Chat is between playing
          players only. To play new game or abort current click on settings
          button left corner and join/create new game. If player aborts game it
          is shown in black playfield for player and the game continues for the
          others. One player can only join one game at a time. If you create a
          game type already offered you will join that game instead. To test
          multiplayer you can open this webpage in several tabs each will
          connect as new player.
          <br />
          Boardanimation can be turned on/off in settings 'General' tab and
          there is also an option for changing language.
        </p>

        <p className="instructions-flutter">
        React and Flutter integration on web platform demonstrated. The Flutter app uses flutter_bloc for state management, relying on one cubit to handle all updates, including language and asynchronous changes. 
        The app is modular and extensive, with modules like chat, top scores, and network injected in one step. The modules update automatically when the language changes, accessible through a passed-in function. 
        The chat textfield has isolated state changes and requires a passed-in function to issue state changes through the cubit. 
        The goal is to simplify and test, resulting in a minimal and modular programming system for multi-user communication on all devices over the internet.
          <br />
          
          
        </p>
        </main>
    </>
  );
}

const mapStateToProps = (state) => ({
  settings: getFlutterSettings(state),
});

const mapDispatchToProps = (dispatch) => ({
  onSaveSettingsFlutter: (settings) => dispatch(saveSettingsFlutter(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Flutter);
